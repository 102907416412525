import { Injectable } from '@angular/core';
import { ApiItemsService } from './methods/items';
import { ApiAuthCheckService } from './methods/auth-check';
import { ApiAuthLoginService } from './methods/auth-login';
import { ApiAuthLogoutService } from './methods/auth-logout';
import { ApiNotificationsAttachmentTypesService } from '@core/service/api/methods/notifications-attachment-types';
import { ApiNotificationsService } from '@core/service/api/methods/notifications';
import { ApiOrdersService } from '@core/service/api/methods/orders';
import { ApiOrdersStatusService } from '@core/service/api/methods/orders-status';
import { ApiSpecialsService } from '@core/service/api/methods/specials';
import { ApiSpecialsStatusesService } from '@core/service/api/methods/specials-statuses';
import { ApiGroupsService } from '@core/service/api/methods/groups';
import { ApiElemsAttachmentsService } from "@core/service/api/methods/elems-attachments";
import { ApiCitiesService } from "@core/service/api/methods/cities";
import { ApiBasketService } from "@core/service/api/methods/basket";
import { TokenService } from "@core/service/api/methods/token";
import { ApiRegionsService } from "@core/service/api/methods/regions";
import { ApiStoriesService } from "@core/service/api/methods/stories";


@Injectable()
export class ApiService {
    constructor(
        public authCheck: ApiAuthCheckService,
        public authLogin: ApiAuthLoginService,
        public authLogout: ApiAuthLogoutService,
        public items: ApiItemsService,
        public notifications: ApiNotificationsService,
        public notificationsAttachmentTypes: ApiNotificationsAttachmentTypesService,
        public orders: ApiOrdersService,
        public ordersStatus: ApiOrdersStatusService,
        public specials: ApiSpecialsService,
        public specialsStatuses: ApiSpecialsStatusesService,
        public groups: ApiGroupsService,
        public elemsAttachment: ApiElemsAttachmentsService,
        public cities: ApiCitiesService,
        public basket: ApiBasketService,
        public token: TokenService,
        public regions: ApiRegionsService,
        public stories: ApiStoriesService

    ) {
    }

}

