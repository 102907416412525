import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from "@angular/common/http";
import * as _ from "lodash";
import { forEach, size } from "lodash";
import { Cookie } from "../cookie";
import { CookieService } from "ngx-cookie";
import { HmacSHA256 } from "crypto-js";
import { Config } from "../config";
import { Log } from "../logger";
import { DOCUMENT } from "@angular/common";

export interface Response {
    status: number;
    json: any;
}

@Injectable()
export class ApiRequest {
    protected data: {} = {};
    protected json: string;

    constructor(private _http: HttpClient,
        private _config: Config,
        private _log: Log,
        private _cookie: Cookie,
        private _cookieService: CookieService,
        @Inject(DOCUMENT) private _document) {
    }

    /**
     *
     *
     * @param {string} _path
     * @param {Object} _data
     * @param {string[]} _files
     * @param {boolean} _isServer
     * @return {Promise<Response>}
     */
    public query(_path: string, _data: object = {}) {
        this.params(_data);

        const tmpUrl: string = this.createUrl(_path);

        let body = this.paramsForToken(_data);
        let url = tmpUrl;

        if (!this.isEmptyObject(_data)) {
            url = tmpUrl + '?' + body;
        }

        this._log.debug('GET', url);

        return this._http
            .get(url, { headers: this.header() })
            .toPromise()
            .then((body: any) => {
                return this.reply(body);
            }).catch((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this._cookie.removeItem('token');
                } else {
                    throw this.replyError(error);
                }
            });
    }

    isEmptyObject(obj) {
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Обновления
     *
     * @param {string} _path
     * @param {Object} _data
     * @param _files
     * @return {Promise<Response>}
     */
    public update(_path: string, _data: object = {}, _files: File[] = []) {
        this.params(_data);

        const url: string = this.createUrl(_path);
        this._log.debug('POST', url);

        let header = this.header();
        let body: any;

        body = this.json;

        return this._http
            .put(url, body, { headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);

            }).catch((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this._cookie.removeItem('token');
                } else {
                    throw this.replyError(error);
                }
            });
    }

    /**
     * copy
     * @param _path
     * @param _data
     * @param _files
     * @returns {Promise<TResult|TResult2|Response>}
     */

    public copy(_path: string, _data: object = {}, _files: File[] = []) {
        this.params(_data);

        const url: string = this.createUrl(_path);
        this._log.debug('POST', url);

        let header = this.header();
        let body: any;

        body = this.json;

        return this._http
            .request('COPY', url, { body: body, headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);

            }).catch((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this._cookie.removeItem('token');
                } else {
                    throw this.replyError(error);
                }
            });
    }


    public patch(_path: string, _data: object = {}) {
        this.params(_data);

        const url: string = this.createUrl(_path);
        this._log.debug('PATCH', url);

        let header = this.header();
        let body: any;

        body = this.json;

        return this._http
            .patch(url, body, { headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);

            }).catch((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this._cookie.removeItem('token');
                } else {
                    throw this.replyError(error);
                }
            });
    }

    /**
     * Создания
     *
     * @param {string} _path
     * @param {Object} _data
     * @param {string[]} _files
     * @return {Promise<Response>}
     */
    public create(_path: string, _data: object = {}) {
        this.params(_data);

        const url: string = this.createUrl(_path);
        this._log.debug('POST', url);

        let header = this.header();
        let body: any;

        body = this.json;

        return this._http
            .post(url, body, { headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);
            }).catch((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this._cookie.removeItem('token');
                } else {
                    throw this.replyError(error);
                }
            });
    }

    postForToken(_path: string, _data: object = {}) {

        const tmpUrl: string = this.createUrl(_path);
        this._log.debug('POST', tmpUrl);

        let header = this.header();
        let body = this.paramsForToken(_data);
        let url = tmpUrl + '?' + body;
        return this._http
            .post(url, null, { headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);
            }).catch((error: HttpErrorResponse) => {
                throw this.replyError(error);
            });
    }

    public paramsForToken(data) {
        return Object.keys(data).map(key => `${key}=${(data[key])}`).join('&');
    }

    /**
     * Возращает отформатированный ответ из запроса
     *
     * @param {Response} res
     * @return {ApiResponse}
     */
    private reply(res: Response) {
        const reply = {
            json: res
        };
        return <Response>reply;
    }

    /**
     * Возращает отформатированный ответ ошибки
     *
     * @param {HttpErrorResponse} res
     *
     * @return {}
     */
    private replyError(res: HttpErrorResponse) {
        const reply = {
            message: res.message,
            status: res.status
        };

        return <{ message: string; status: number }>reply;
    }


    /**
     * Создания url
     *
     * @param path
     * @returns {string}
     */
    protected createUrl(path: string): string {
        let urlPath = '';
        const api = this._config.get('api');
        const port = api['port'];
        const protocol = api['protocol'];
        const url = api['url'];

        urlPath += protocol + '://';
        urlPath += url;
        if (port) {
            urlPath += ':' + port;
        }
        urlPath += '/' + path;

        return urlPath;
    }

    /**
     * Возращает header
     *
     * @param params
     * @returns {Headers}
     */
    protected header(params: {} = {}): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({});
        const configHeader = this._config.get('api')['header'];

        /**
         * Конфиг
         */
        forEach(configHeader, function(val, key) {
            headers = headers.append(key.toString(), val.toString());
        });

        /**
         * Входящие параметры
         */
        if (params) {
            forEach(params, function(val: any, key: any) {
                headers = headers.set(<string>key, <string>val);
            });
        }

        /**
         * Токен
         */
        const token = (this._cookieService.get('token')) ? this._cookieService.get('token') : '';
        if (token.length > 0) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }

        return headers;
    }


    /**
     * Подготовка данных
     *
     * @param _data обьект с данными
     *
     * @returns {string}
     */
    protected params(_data: object): void {
        this.data = _data;
        this.json = <string>JSON.stringify(this.data);
    }

    /**
     * Создания токен
     */
    protected createToken(): string {

        const api = this._config.get('api');
        const secret = <string>api['secret'];
        const dataJson = JSON.stringify(this.data);

        const token = <string>HmacSHA256(dataJson, secret)
            .toString()
            .toUpperCase();

        this._log.debug('Token data', this.data);
        this._log.debug('Token json', dataJson);
        this._log.debug('Token', token);

        return token;
    }
}
