import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Get, InGet, InPut, Put } from '@core/service/api/methods/orders/interface';

@Injectable()
export class ApiOrdersService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Получение списка заказов
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: InGet): Promise<Get> {
        return this._request
            .query('ma/arm/v1/orders', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }


    /*
     * Изменение заказа
     *
     * @param {InPut} obj
     * @return {Promise<Put>}
     */
    put(obj: InPut): Promise<Put> {
        return this._request
            .update('ma/arm/v1/orders', obj)
            .then((res: Response) => res.json);
    }

}

