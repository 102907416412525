import { Injectable } from '@angular/core';
import { IMenu } from './interface';

@Injectable()
export class AsideNavService {

    constructor() {
    }

    /**
     * Список менюшки
     *
     * @returns {any[]}
     */
    static getListMenu(): any[] {
        return [{
            title: 'Рассылки',
            link: false,
            icon: 'flaticon-alarm-1',
            children: [{
                title: 'Новая рассылка',
                link: '/notifications/new'
            }, {
                title: 'Архив рассылок',
                link: '/notifications/archive'
            }]
        }/*, {
            title: 'Выборки',
            link: false,
            icon: 'flaticon-list-1',
            children: [{
                title: 'Новая выборка',
                link: '/selections/new'
            }, {
                title: 'Сохраненные выборки',
                link: '/selections/list'
            }],
        }, {
            title: 'Акции',
            link: false,
            icon: 'flaticon-gift',
            children: [{
                title: 'Новая акция',
                link: '/offers/new'
            }, {
                title: 'Архив акций',
                link: '/offers/archive'
            }],
        }, {
            title: 'Статистика',
            link: false,
            icon: 'flaticon-graphic-2',
            children: [{
                title: 'Брошенная корзина',
                link: '/stats/basket'
            }],
        }*/];
    }
}
