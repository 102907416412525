import { Injectable } from '@angular/core';
import { ApiRequest } from './../../request';
import * as Interface from './../../interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiAuthLogoutService extends ApiUtilsService {

    constructor(protected _request: ApiRequest, protected _activatedRoute: ActivatedRoute, protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Сброс авторизации
     *
     * @return {Promise}
     */
    get() {
        return this._request.query('api/v0.1/connect/logout/');
    }
}

