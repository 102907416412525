import { Injectable } from '@angular/core';
import { ApiRequest } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Response } from '@core/service/api/request';
import { InPost } from "@core/service/api/methods/token/interface";

@Injectable()
export class TokenService extends ApiUtilsService {

    constructor(protected _request: ApiRequest, protected _activatedRoute: ActivatedRoute, protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Получение токена
     *
     * @return {Promise<Post>}
     */
    post(obj?: InPost): Promise<any> {
        return this._request
            .postForToken('api/v0.1/connect/token', obj)
            .then((res: Response) => res.json);
    }
}

