import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../helpers';
import { Auth } from '@core/service/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BackUrl } from '@core/service/back-url';
import { Router } from '@angular/router';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './auth.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AuthComponent implements OnInit {
    public loading = false;
    public form: FormGroup;
    public submit: boolean = false;
    public messageFromApi: string = '';

    constructor(private _auth: Auth, private _backUrl: BackUrl, private _router: Router) {
    }

    ngOnInit() {
        Helpers.setLoading(false);

        this.form = new FormGroup({
            login: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });

        /*this._auth.check()
            .then((res) => {
                if (res === true) {
                    this._router.navigateByUrl('/');
                }
            });*/
    }

    OnClickSignIn() {
        this.submit = true;

        if (this.form.valid) {
            this.loading = true;
            this._auth.login(this.form.get('login').value, this.form.get('password').value)
                .then((res) => {
                    this._backUrl.redirect();
                    this.loading = false;
                }).catch((err) => {
                    this.messageFromApi = err.message;
                    this.loading = false;
                });
        }

    }
}