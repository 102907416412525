import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { Routes, RouterModule } from '@angular/router';
import { Guard } from '@core/service/auth/guard';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [Guard],
        canActivateChild: [MetaGuard],
        children: [
            {
                path: 'notifications',
                loadChildren: './notifications/notifications.module#NotificationsModule',
            },
            {
                path: '',
                redirectTo: 'notifications',
                pathMatch: 'full',
            }

        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {
}
