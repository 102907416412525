/* Module */
import { NgModule } from '@angular/core';

/* Test */
import { fakeBackendProvider } from './mock';

/* Service */
import { ApiItemsService } from '@core/service/api/methods/items';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from '@core/service/api/api';
import { ApiAuthCheckService } from '@core/service/api/methods/auth-check';
import { ApiAuthLoginService } from '@core/service/api/methods/auth-login';
import { ApiAuthLogoutService } from '@core/service/api/methods/auth-logout';
import { ApiRequest } from '@core/service/api/request';
import { ApiNotificationsService } from '@core/service/api/methods/notifications';
import { ApiNotificationsAttachmentTypesService } from '@core/service/api/methods/notifications-attachment-types';
import { ApiOrdersService } from '@core/service/api/methods/orders';
import { ApiOrdersStatusService } from '@core/service/api/methods/orders-status';
import { ApiSpecialsService } from '@core/service/api/methods/specials';
import { ApiSpecialsStatusesService } from '@core/service/api/methods/specials-statuses';
import { ApiGroupsService } from '@core/service/api/methods/groups';
import { ApiElemsAttachmentsService } from "@core/service/api/methods/elems-attachments";
import { ApiCitiesService } from "@core/service/api/methods/cities";
import { ApiBasketService } from "@core/service/api/methods/basket";
import { TokenService } from "@core/service/api/methods/token";
import { ApiRegionsService } from "@core/service/api/methods/regions";
import { ApiStoriesService } from "@core/service/api/methods/stories";

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [
        ApiRequest,
        ApiService,
        ApiAuthCheckService,
        ApiAuthLoginService,
        ApiAuthLogoutService,
        ApiItemsService,
        ApiNotificationsService,
        ApiNotificationsAttachmentTypesService,
        ApiOrdersService,
        ApiOrdersStatusService,
        ApiSpecialsService,
        ApiSpecialsStatusesService,
        ApiGroupsService,
        ApiElemsAttachmentsService,
        ApiCitiesService,
        ApiBasketService,
        TokenService,
        ApiRegionsService,
        ApiStoriesService,
        // api backend simulation
        fakeBackendProvider,
    ],
    exports: []
})

export class ApiModule {
}
