export const environment = {
    production: false,
    api: {
        url: 'rc.asna.ru',
        secret: 'v9qCEkUX5ln06QipE5rgUajoJKO86cvZUhA8o6nJVVBObvoH9J8KAx3ZIamJbMXn',
        clientSecret: 'R$4o%5E5nw4TqK',
        clientId: 'cc8f1eaf-1799-4d38-b659-cb24cd353629',
        grantType: 'client_credentials',
        port: '',
        protocol: 'https',
        ncp: true,
        header: {
            'Content-Type': 'application/json'
        }
    },
    debug: false,
    site: {
        date: {
            full: 'DD.MM.YYYY HH:mm',
            time: 'HH:mm',
            date: 'DD.MM.YYYY'
        },
        def: {
            elementCount: 15,
            startPage: 1,
            autoCompleteCount: 10,
            perPageList: [5, 15, 30],
            showPageCount: 7
        },
        updateTime: 5, // Интервал обновления данных, в секундах
        checkAuthTime: 1, // Интервал проверки авторизации, в секундах
        logoutTime: 30, // Время бездействия после которого нужна будет авторизация, в минутах
    },

};
