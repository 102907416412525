import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { InPost, Get, InGet, InRepeat, InRemove } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiNotificationsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Создание и отправка
     *
     * @param {InPost} obj
     * @return {Promise<any>}
     */
    post(obj: any): Promise<any> {
        return this._request.create('api/v0.1/notifications/', obj)
            .then((res: Response) => {
                return res.json;
            }).catch(() => {
                return { result: false };
            });
    }

    /*
     * Список уведомлений
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: any): Promise<any> {
        return this._request
            .query('api/v0.1/notifications/', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /**
     * Повтор рассылки
     * @param {InRepeat} id
     * @param {InRepeat} obj
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    repeat(obj: any, id: number): Promise<any> {
        return this._request.copy('api/v0.1/notifications/' + id, obj)
            .then((res: Response) => {
                return res.json;
            })
    }

    /**
     * Удаление рассылки
     * @param obj
     * @param id
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    remove(obj: any, id: number): Promise<any> {
        return this._request.patch('api/v0.1/notifications/' + id, obj)
            .then((res: Response) => {
                return res.json;
            })
    }
}

