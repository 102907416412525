import { Injectable } from '@angular/core';
import { ApiRequest } from './../../request';
import { InGet, Get, InPut, Put } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Response } from '@core/service/api/request';

@Injectable()
export class ApiItemsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Список товаров
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/items', obj)
            .then((res: Response) => res.json);
    }

    put(_obj?: InPut): Promise<Put> {
        return this._request
            .update('ma/arm/v1/items', _obj)
            .then((res: Response) => res.json);
    }
}

