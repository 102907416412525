import { Injectable } from '@angular/core';
import { Auth } from './auth';
import { Config } from '../config';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class Guard {
    private active: boolean = false;

    constructor(private _auth: Auth, private _router: Router, private _config: Config) {
        this.checkAuthTime();
        // this.logoutTime();
    }

    /**
     *
     * @returns {boolean}
     */
    canActivate(): boolean {
        if (!this._auth.getToken()) {
            this._router.navigate(['/login']);
            this.active = false;
        } else {
            this.active = true;
        }
        return this.active;
    }

    checkAuthTime() {
        const config = this._config.get('site');
        let checkAuthTime = config.checkAuthTime;
        if (!checkAuthTime) {
            checkAuthTime = 5;
        }
        const intervalObs = interval(1000 * checkAuthTime);

        intervalObs
            .pipe(
                filter(() => {
                    return this.active;
                })
            ).subscribe(() => {
                if (!this._auth.getToken()) {
                    this._router.navigate(['/login']);
                }
            });
    }

    logoutTime() {
        const config = this._config.get('site');
        let logoutTime = config.logoutTime;
        if (!logoutTime) {
            logoutTime = 30;
        }
        const intervalObs = interval(1000 * 60 * logoutTime);

        intervalObs
            .pipe(
                filter(() => {
                    return this.active;
                })
            )
            .subscribe(() => {
                this._auth
                    .logout()
                    .then((resApi) => {
                        this.active = false;
                        this._router.navigate(['/login']);
                    });
            });
    }
}
