import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Get, InGet, InPut, Put } from '@core/service/api/methods/specials/interface';

@Injectable()
export class ApiSpecialsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка новостей
     *
     * @return {Promise<Get>}
     */
    get(obj?: any): Promise<any> {
        return this._request
            .query('api/v0.1/salesacts/', obj)
            .then((res: Response) => res.json);
    }


    /*
     * Получение списка новостей
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    put(obj?: InPut): Promise<Put> {
        return this._request
            .update('ma/arm/v1/specials', obj)
            .then((res: Response) => res.json);
    }

}

