import { Injectable } from '@angular/core';
import { Cookie } from '../cookie';
import { ApiService } from '../api';
import { BehaviorSubject } from 'rxjs';
import { Config } from "@core/service/config";

@Injectable()
export class Auth {
    private nameAuthToken = 'token';
    private nameUser = 'login';

    public resultCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private _api: ApiService, private _cookie: Cookie, private _config: Config) { }

    /**
     * Получение токена
     *
     * @param {string} login
     * @param {string} password
     *
     * @returns {Promise<boolean>}
     */
    login(login: string, password: string) {

        let client_id = this._config.get('api')['clientId'];
        let client_secret = this._config.get('api')['clientSecret'];
        let grant_type = this._config.get('api')['grantType'];

        const obj = {
            data: {
                type: 'user',
                attributes: {
                    login: login,
                    password: password
                }
            }
        };

        const tokenParams = {
            clientSecret: client_secret,
            clientId: client_id,
            grantType: grant_type
        };

        this.deleteToken();

        return this._api.token.post(tokenParams)
            .then((res) => {
                let access_token = res['access_token'];
                this.setToken(access_token);
                return this._api
                    .authLogin.post(obj)
                    .then((res: any) => {
                        this.setToken(res['access_token']);
                        this.setUser(login);
                        return true;
                    })
            })
    }

    /**
     * Выход 
     * 
     * @returns {Promise<boolean>}
     **/
    logout() {
        return this._api
            .authLogout.get()
            .then((res: any) => {
                this.deleteToken();
                return true;
            }).catch((err) => {
                if (err.status == 401) {
                    this.deleteToken();
                    return true;
                } else {
                    return false;
                }
            });
    }

    /**
     * Запись auth token
     *
     * @param {string} token
     */
    setToken(token: string): void {
        this._cookie.setItem(this.nameAuthToken, token);
    }

    /**
     * Возращает токен
     *
     * @returns {string}
     */
    getToken(): string {
        return <string>this._cookie.getItem(this.nameAuthToken);
    }

    deleteToken() {
        this._cookie.removeItem(this.nameAuthToken);
    }


    /**
     *   Проверка токена
     * @returns {Promise<boolean>}
     */
    check(): Promise<boolean> {
        if (this.getToken()) {
            return this._api
                .authCheck
                .post()
                .then((res: any) => {
                    const result = <boolean>res.result;
                    this.resultCheck.next(result);

                    if (result === false) {
                        this._cookie.removeItem(this.nameAuthToken);
                    }

                    return result;
                }).catch((err: any) => {
                    this._cookie.removeItem(this.nameAuthToken);
                    this.resultCheck.next(false);
                    return false;
                });
        }

        return new Promise((resolve) => {
            resolve(false);
        });
    }

    /**
     *  Запись логина
     * @param login
     */

    setUser(login: string): void {
        if (login.length > 0) {
            this._cookie.setItem(this.nameUser, login);
        }
    }

    /**
     * Получение логина
     * @returns {string}
     */
    getUser(): string {
        return <string>this._cookie.getItem(this.nameUser);
    }
}
